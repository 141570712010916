const _baseLocalStorageText = "InstaPos";
const _baseRole = "Member";

const tokenKey = _baseLocalStorageText + _baseRole + "Token";
const currentUserData = _baseLocalStorageText + _baseRole + "Data";
const currentUserId = _baseLocalStorageText + _baseRole + "Id";
const currentUserName = _baseLocalStorageText + _baseRole + "Name";
const languagePath = _baseLocalStorageText + _baseRole + "LanguagePath";
const languageName = _baseLocalStorageText + _baseRole + "LanguageName";
const languageId = _baseLocalStorageText + _baseRole + "LanguageId";
const languageImage = _baseLocalStorageText + _baseRole + "LanguageImage";
const userLat = _baseLocalStorageText + _baseRole + "lat"; //"Latitude";
const userLng = _baseLocalStorageText + _baseRole + "lng"; //"Longitude";
const userLocation = _baseLocalStorageText + _baseRole + "UserLocation";
const userPrevRoute = _baseLocalStorageText + _baseRole + "PrevRoute";
const providerId = _baseLocalStorageText + _baseRole + "ProviderId";
const shopRoute = _baseLocalStorageText + _baseRole + "ShopRoute";
const shopId = _baseLocalStorageText + _baseRole + "ShopId";
const UUId = _baseLocalStorageText + _baseRole + "Uuid";
const stockId = _baseLocalStorageText + _baseRole + "StockId";
const orderType = _baseLocalStorageText + _baseRole + "OrderType";
const checkoutData = _baseLocalStorageText + _baseRole + "CheckoutData";
const cartData = _baseLocalStorageText + _baseRole + "CartData";
const searchData = _baseLocalStorageText + _baseRole + "SearchData";
const discountId = _baseLocalStorageText + _baseRole + "DiscountId";
const posDiscountId = _baseLocalStorageText + _baseRole + "PosDiscountId";
const shopType = _baseLocalStorageText + _baseRole + "ShopTypes";
const tableId = _baseLocalStorageText + _baseRole + "TableId";

// For Token Start
const setLocalStorageToken = (data) => {
  localStorage.setItem(tokenKey, data);
};

const getLocalStorageToken = () => {
  return localStorage.getItem(tokenKey);
};

const removeLocalStorageToken = () => {
  localStorage.removeItem(tokenKey);
};
// For Token End

// For CurrentUserData Start
const setLocalStorageCurrentUserData = (data) => {
  localStorage.setItem(currentUserData, JSON.stringify(data));
};

const getLocalStorageCurrentUserData = () => {
  let data = localStorage.getItem(currentUserData);
  if (data) {
    data = JSON.parse(data);
  }

  return data;
};

const removeLocalStorageCurrentUserData = () => {
  localStorage.removeItem(currentUserData);
};
// For CurrentUserData End

// For CurrentUserId Start
const setLocalStorageCurrentUserId = (data) => {
  localStorage.setItem(currentUserId, data);
};

const getLocalStorageCurrentUserId = () => {
  return localStorage.getItem(currentUserId);
};

const removeLocalStorageCurrentUserId = () => {
  localStorage.removeItem(currentUserId);
};
// For CurrentUserId End

// For CurrentUserName Start
const setLocalStorageCurrentUserName = (data) => {
  localStorage.setItem(currentUserName, data);
};

const getLocalStorageCurrentUserName = () => {
  return localStorage.getItem(currentUserName);
};

const removeLocalStorageCurrentUserName = () => {
  localStorage.removeItem(currentUserName);
};
// For CurrentUserName End

// For LanguagePath Start
const setLocalStorageLanguagePath = (data) => {
  localStorage.setItem(languagePath, data);
};

const getLocalStorageLanguagePath = () => {
  return localStorage.getItem(languagePath);
};

const removeLocalStorageLanguagePath = () => {
  localStorage.removeItem(languagePath);
};
// For LanguagePath End

// For LanguageName Start
const setLocalStorageLanguageName = (data) => {
  localStorage.setItem(languageName, data);
};

const getLocalStorageLanguageName = () => {
  return localStorage.getItem(languageName);
};

const removeLocalStorageLanguageName = () => {
  localStorage.removeItem(languageName);
};
// For LanguageName End

// For LanguageId Start
const setLocalStorageLanguageId = (data) => {
  localStorage.setItem(languageId, data);
};

const getLocalStorageLanguageId = () => {
  return localStorage.getItem(languageId);
};

const removeLocalStorageLanguageId = () => {
  localStorage.removeItem(languageId);
};
// For LanguageId End

// For LanguageImage Start
const setLocalStorageLanguageImage = (data) => {
  localStorage.setItem(languageImage, data);
};

const getLocalStorageLanguageImage = () => {
  return localStorage.getItem(languageImage);
};

const removeLocalStorageLanguageImage = () => {
  localStorage.removeItem(languageImage);
};
// For LanguageImage End

// For User Lat Lng and user user location
const setLocalStorageUserLat = (data) => {
  localStorage.setItem(userLat, data);
};
const setLocalStorageUserLng = (data) => {
  localStorage.setItem(userLng, data);
};
const setLocalStorageUserLocation = (data) => {
  localStorage.setItem(userLocation, data);
};
const getLocalStorageUserLocation = () => {
  const ul = localStorage.getItem(userLocation);
  if (ul) {
    return JSON.parse(ul);
  }
  return { lat: 16.80528, lng: 96.15611 };
};

// prevRoute
const setLocalStorageUserPrevRoute = (data) => {
  localStorage.setItem(userPrevRoute, data);
};
const getLocalStorageUserPrevRoute = () => {
  return localStorage.getItem(userPrevRoute);
};
const removeLocalStorageUserPrevRoute = () => {
  localStorage.removeItem(userPrevRoute);
};

// Provider
const setLocalStorageUserProvider = (data) => {
  localStorage.setItem(providerId, data);
};
const getLocalStorageUserProvider = () => {
  return localStorage.getItem(providerId);
};
const removeLocalStorageUserProvider = () => {
  localStorage.removeItem(providerId);
};

// Shop Route
const setLocalStorageShopRoute = (data) => {
  localStorage.setItem(shopRoute, data);
};
const getLocalStorageShopRoute = () => {
  return localStorage.getItem(shopRoute);
};
const removeLocalStorageShopRoute = () => {
  localStorage.removeItem(shopRoute);
};

// Shop id
const setLocalStorageShopId = (data) => {
  localStorage.setItem(shopId, data);
};
const getLocalStorageShopId = () => {
  return parseInt(localStorage.getItem(shopId) || 0);
};
const removeLocalStorageShopId = () => {
  localStorage.removeItem(shopId);
};

// UUID
export const setLocalStorageUuid = (data) => {
  localStorage.setItem(UUId, data);
};
export const getLocalStorageUuid = () => {
  return localStorage.getItem(UUId);
};
export const removeLocalStorageUuid = () => {
  localStorage.removeItem(UUId);
};

// STOCK ID
export const setLocalStorageStockId = (data) => {
  localStorage.setItem(stockId, data);
};
export const getLocalStorageStockId = () => {
  return localStorage.getItem(stockId);
};
export const removeLocalStorageStockId = () => {
  localStorage.removeItem(stockId);
};

// order type
export const setLocalStorageOrderTypeInfo = (data) => {
  localStorage.setItem(orderType, JSON.stringify(data));
};
export const getLocalStorageOrderTypeInfo = () => {
  let data = localStorage.getItem(orderType);

  if (data) {
    data = JSON.parse(data);
  } else {
    data = { name: "", value: "", time: "" };
  }
  return data;
};
export const removeLocalStorageOrderTypeInfo = () => {
  localStorage.removeItem(orderType);
};

// check out data
export const setLocalStorageCheckoutData = (data) => {
  localStorage.setItem(checkoutData, JSON.stringify(data));
};
export const getLocalStorageCheckoutData = () => {
  let data = localStorage.getItem(checkoutData);

  if (data) {
    data = JSON.parse(data);
  }

  return data;
};
export const removeLocalStorageCheckoutData = () => {
  localStorage.removeItem(checkoutData);
  removeLocalStoragePosDiscountId();
};

// cart data
export const setLocalStorageCartData = (data) => {
  localStorage.setItem(cartData, JSON.stringify(data));
};
export const getLocalStorageCartData = () => {
  let data = localStorage.getItem(cartData);

  if (data) {
    data = JSON.parse(data);
  }
  return data;
};
export const removeLocalStorageCartData = () => {
  localStorage.removeItem(cartData);
  removeLocalStoragePosDiscountId();
};

// Search data
export const setLocalStorageSearchData = (data = []) => {
  if (data.length > 0) {
    data = [...new Set(data)];
    data = data.filter((item) => item !== "");
  }
  localStorage.setItem(searchData, JSON.stringify(data));
};
export const getLocalStorageSearchData = () => {
  let data = localStorage.getItem(searchData) || "[]";

  if (data) {
    data = JSON.parse(data);
  }
  return data;
};
export const removeLocalStorageSearchData = () => {
  localStorage.removeItem(searchData);
};

// DiscountId
export const setLocalStorageDiscountId = (data) => {
  if (data > 0) {
    localStorage.setItem(discountId, data);
  }
};
export const getLocalStorageDiscountId = () => {
  return localStorage.getItem(discountId);
};
export const removeLocalStorageDiscountId = () => {
  localStorage.removeItem(discountId);
};
// PosDiscountId
export const setLocalStoragePosDiscountId = (data) => {
  if (data > 0) {
    localStorage.setItem(posDiscountId, data);
  }
};
export const getLocalStoragePosDiscountId = () => {
  return localStorage.getItem(posDiscountId);
};
export const removeLocalStoragePosDiscountId = () => {
  localStorage.removeItem(posDiscountId);
};

export const setLocalStorageShopTypes = (data) => {
  localStorage.setItem(shopType, JSON.stringify(data));
};
export const getLocalStorageShopTypes = () => {
  let data = localStorage.getItem(shopType);
  if (data) {
    data = JSON.parse(data);
  }
  return data;
};

export const setLocalStorageTableId = (data) => {
  if (data > 0) {
    localStorage.setItem(tableId, data);
  }
};
export const getLocalStorageTableId = () => {
  let id = parseInt(localStorage.getItem(tableId) || 0);
  return id;
};

// Remove All LocalStorage Items
const removeAllLocalStorageItems = () => {
  removeLocalStorageToken();
  removeLocalStorageCurrentUserData();
  removeLocalStorageCurrentUserId();
  removeLocalStorageCurrentUserName();
  removeLocalStorageUserPrevRoute();
  removeLocalStorageUserProvider();
  removeLocalStorageUserProvider();
  // removeLocalStorageLanguagePath();
  // removeLocalStorageLanguageName();
  // removeLocalStorageLanguageId();
  // removeLocalStorageLanguageImage();
};

export {
  // Token
  tokenKey,
  setLocalStorageToken,
  getLocalStorageToken,
  removeLocalStorageToken,
  // CurrentUserData
  setLocalStorageCurrentUserData,
  getLocalStorageCurrentUserData,
  removeLocalStorageCurrentUserData,
  // CurrentUserId
  setLocalStorageCurrentUserId,
  getLocalStorageCurrentUserId,
  removeLocalStorageCurrentUserId,
  // CurrentUserName
  setLocalStorageCurrentUserName,
  getLocalStorageCurrentUserName,
  removeLocalStorageCurrentUserName,
  // LanguagePath
  setLocalStorageLanguagePath,
  getLocalStorageLanguagePath,
  removeLocalStorageLanguagePath,
  // LanguageName
  setLocalStorageLanguageName,
  getLocalStorageLanguageName,
  removeLocalStorageLanguageName,
  // LanguageId
  setLocalStorageLanguageId,
  getLocalStorageLanguageId,
  removeLocalStorageLanguageId,
  // LanguageImage
  setLocalStorageLanguageImage,
  getLocalStorageLanguageImage,
  removeLocalStorageLanguageImage,
  // For User Lat Lng and user user location
  setLocalStorageUserLat,
  setLocalStorageUserLng,
  setLocalStorageUserLocation,
  getLocalStorageUserLocation,
  // For User Prev Route
  setLocalStorageUserPrevRoute,
  getLocalStorageUserPrevRoute,
  removeLocalStorageUserPrevRoute,
  // For Provider
  setLocalStorageUserProvider,
  getLocalStorageUserProvider,
  removeLocalStorageUserProvider,
  // For Shop Route
  setLocalStorageShopRoute,
  getLocalStorageShopRoute,
  removeLocalStorageShopRoute,
  // For Shop Id
  setLocalStorageShopId,
  getLocalStorageShopId,
  removeLocalStorageShopId,
  // Remove All
  removeAllLocalStorageItems,
};
