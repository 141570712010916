import http from "../../services/httpService";
import config from "../../config.json";

const { apiUrl } = config;
const apiEndPoint = apiUrl + "api/cart/";

export function countProductFromCart(params) {
  return http.get(apiEndPoint + "countProductFromCart", params);
}

export function addToCart(data) {
  return http.post(apiEndPoint + "addToCart", data);
}

export function getProductFromCart(params) {
  return http.get(apiEndPoint + "getProductFromCart", params);
}

export function getProductFromCartByShopId(params) {
  return http.get(apiEndPoint + "getProductFromCartByShopId", params);
}

export function updateCartProductQuantity(data) {
  return http.post(apiEndPoint + "updateCartProductQuantity", data);
}

export function deleteFromCart(id) {
  return http.post(apiEndPoint + "removeFromCart/" + id);
}

export function deleteProductFromCart(id) {
  return http.post(apiEndPoint + "removeProductFromCart/" + id);
}

export function addDiscountItemToCart(data) {
  return http.post(apiEndPoint + "addDiscountItemToCart", data);
}

export function updateCartProduct(data) {
  return http.post(apiEndPoint + "updateCartProduct", data);
}
