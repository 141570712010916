import React from "react";
import Loadable from "react-loadable";
import { Loading as LoadingDiv } from "./components/common/index";

// Loading function
function Loading() {
  return <LoadingDiv isLoad={true} size="lg" />;
}

// Start Components

const Layout = Loadable({
  loader: () => import("./theme/Layout"),
  loading: Loading,
});

const Home = Loadable({
  loader: () => import("./components/home/home"),
  loading: Loading,
});

const Page404 = Loadable({
  loader: () => import("./theme/pages/page404"),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import("./components/auth/login"),
  loading: Loading,
});

const Register = Loadable({
  loader: () => import("./components/auth/register"),
  loading: Loading,
});

const LineLogin = Loadable({
  loader: () => import("./components/auth/lineLogin"),
  loading: Loading,
});

const FacebookLogin = Loadable({
  loader: () => import("./components/auth/facebookLogin"),
  loading: Loading,
});

const GoogleLogin = Loadable({
  loader: () => import("./components/auth/googleLogin"),
  loading: Loading,
});

const QrScanner = Loadable({
  loader: () => import("./components/home/qrScanner"),
  loading: Loading,
});

const MyQr = Loadable({
  loader: () => import("./components/home/myQr"),
  loading: Loading,
});

const ShopList = Loadable({
  loader: () => import("./components/shop/shopList"),
  loading: Loading,
});

const ShopHome = Loadable({
  loader: () => import("./components/shop/shopHome"),
  loading: Loading,
});

const ShopDetail = Loadable({
  loader: () => import("./components/shop/shopDetail"),
  loading: Loading,
});

const PointCardList = Loadable({
  loader: () => import("./components/pointCard/pointCardList"),
  loading: Loading,
});

const PointCardDetails = Loadable({
  loader: () => import("./components/pointCard/pointCardDetails"),
  loading: Loading,
});

const TopUp = Loadable({
  loader: () => import("./components/pointCard/topUp"),
  loading: Loading,
});

const TopUpPayment = Loadable({
  loader: () => import("./components/pointCard/topUpPayment"),
  loading: Loading,
});

const TopUpPaymentQr = Loadable({
  loader: () => import("./components/pointCard/topupPaymentQr"),
  loading: Loading,
});

const ProductList = Loadable({
  loader: () => import("./components/product/productList"),
  loading: Loading,
});

const POSProductList = Loadable({
  loader: () => import("./components/pos/product/posProductList"),
  loading: Loading,
});

const ProductDetail = Loadable({
  loader: () => import("./components/product/productDetail"),
  loading: Loading,
});

const CartList = Loadable({
  loader: () => import("./components/cart/cartList"),
  loading: Loading,
});

const POSCartList = Loadable({
  loader: () => import("./components/pos/cart/posCartList"),
  loading: Loading,
});

const CheckOut = Loadable({
  loader: () => import("./components/user/checkOut"),
  loading: Loading,
});

const POSCheckOut = Loadable({
  loader: () => import("./components/pos/user/posCheckOut"),
  loading: Loading,
});

const StampCard = Loadable({
  loader: () => import("./components/stampCard/stampCard"),
  loading: Loading,
});

const Voucher = Loadable({
  loader: () => import("./components/voucher/voucher"),
  loading: Loading,
});

const BuyVoucher = Loadable({
  loader: () => import("./components/voucher/voucherListToBuy"),
  loading: Loading,
});

const VoucherCheckout = Loadable({
  loader: () => import("./components/voucher/voucherCheckout"),
  loading: Loading,
});

const VoucherPayment = Loadable({
  loader: () => import("./components/voucher/voucherPayment"),
  loading: Loading,
});

const MyVoucher = Loadable({
  loader: () => import("./components/voucher/myVoucher"),
  loading: Loading,
});

const ShareVoucher = Loadable({
  loader: () => import("./components/voucher/shareVoucher"),
  loading: Loading,
});

const PosPayment = Loadable({
  loader: () => import("./components/pos/user/posPayment"),
  loading: Loading,
});

const OrderSuccess = Loadable({
  loader: () => import("./components/user/orderSuccess"),
  loading: Loading,
});

const Order = Loadable({
  loader: () => import("./components/user/order"),
  loading: Loading,
});

const CurrentOrderDetail = Loadable({
  loader: () => import("./components/user/currentOrderDetail"),
  loading: Loading,
});

const CompleteOrderDetail = Loadable({
  loader: () => import("./components/user/completeOrderDetail"),
  loading: Loading,
});

const UserAddressForm = Loadable({
  loader: () => import("./components/user/addressForm"),
  loading: Loading,
});

const UserProfile = Loadable({
  loader: () => import("./components/user/userProfile"),
  loading: Loading,
});

const UserAddress = Loadable({
  loader: () => import("./components/user/userAddress"),
  loading: Loading,
});

const AccountSetting = Loadable({
  loader: () => import("./components/user/accountSetting"),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import("./components/user/changePassword"),
  loading: Loading,
});

const UserPhoneChange = Loadable({
  loader: () => import("./components/user/changePhone"),
  loading: Loading,
});

const UserEmailChange = Loadable({
  loader: () => import("./components/user/changeEmail"),
  loading: Loading,
});

const Review = Loadable({
  loader: () => import("./components/user/review"),
  loading: Loading,
});

const ReviewList = Loadable({
  loader: () => import("./components/user/reviewList"),
  loading: Loading,
});

const ForgotPassword = Loadable({
  loader: () => import("./components/auth/forgotPassword"),
  loading: Loading,
});

const ResetPassword = Loadable({
  loader: () => import("./components/auth/resetPassword"),
  loading: Loading,
});

const OrderActivityList = Loadable({
  loader: () => import("./components/user/orderActivityList"),
  loading: Loading,
});

const OrderHistoryList = Loadable({
  loader: () => import("./components/user/orderHistoryList"),
  loading: Loading,
});

const ActivityDetail = Loadable({
  loader: () => import("./components/user/activityDetail"),
  loading: Loading,
});

const OrderConfirm = Loadable({
  loader: () => import("./components/user/orderConfirm"),
  loading: Loading,
});

const PosOrderConfirm = Loadable({
  loader: () => import("./components/pos/user/posOrderConfirm"),
  loading: Loading,
});

const PosOrderActivityList = Loadable({
  loader: () => import("./components/pos/user/posOrderActivityList"),
  loading: Loading,
});

const PosActivityDetail = Loadable({
  loader: () => import("./components/pos/user/posOrderActivityDetail"),
  loading: Loading,
});

const PosOrderHistoryList = Loadable({
  loader: () => import("./components/pos/user/posOrderHistoryList"),
  loading: Loading,
});
const OrderHistoryDetail = Loadable({
  loader: () => import("./components/user/orderHistoryDetail"),
  loading: Loading,
});
const PosOrderHistoryDetail = Loadable({
  loader: () => import("./components/pos/user/posOrderHistoryDetail"),
  loading: Loading,
});

const PromotionList = Loadable({
  loader: () => import("./components/promotion/promotionList"),
  loading: Loading,
});

const ShopViewOnMap = Loadable({
  loader: () => import("./components/shop/viewOnMap"),
  loading: Loading,
});

const Search = Loadable({
  loader: () => import("./components/home/search"),
  loading: Loading,
});

// const PosBillDetail = Loadable({
//   loader: () => import("./components/pos/user/posBillDetail"),
//   loading: Loading,
// });

const BillDetail = Loadable({
  loader: () => import("./components/user/billDetail"),
  loading: Loading,
});

const BillPayment = Loadable({
  loader: () => import("./components/user/billPayment"),
  loading: Loading,
});

const EdcDetail = Loadable({
  loader: () => import("./components/edc/detail"),
  loading: Loading,
});

const RewardList = Loadable({
  loader: () => import("./components/product/rewardList"),
  loading: Loading,
});

const Notification = Loadable({
  loader: () => import("./components/user/notification"),
  loading: Loading,
});

// routes array
const routes = [
  { path: "/*", exact: true, name: "Development", component: Layout },
  { path: "/home", exact: true, name: "Home", component: Home },
  { path: "/page404", exact: true, name: "Page404", component: Page404 },
  { path: "/login", exact: true, name: "Login", component: Login },
  { path: "/register", exact: true, name: "Register", component: Register },
  { path: "/lineLogin", exact: true, name: "LineLogin", component: LineLogin },
  {
    path: "/fblogin",
    exact: true,
    name: "FacebookLogin",
    component: FacebookLogin,
  },
  {
    path: "/googlelogin",
    exact: true,
    name: "GoogleLogin",
    component: GoogleLogin,
  },
  {
    path: "/forgetPassword",
    exact: true,
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/resetPassword/:token",
    exact: true,
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/scanner/:state?",
    name: "Scanner",
    component: QrScanner,
  },
  { path: "/myQr", exact: true, name: "MyQr", component: MyQr },
  {
    path: "/shopList",
    exact: true,
    name: "ShopList",
    component: ShopList,
  },
  {
    path: "/shop/:route",
    exact: true,
    name: "ShopHome",
    component: ShopHome,
  },
  {
    path: "/wallet",
    exact: true,
    name: "PointCardList",
    component: PointCardList,
  },
  {
    path: "/wallet/detail/:id",
    exact: true,
    name: "PointCardDetails",
    component: PointCardDetails,
  },
  {
    path: "/wallet/topup/:id",
    exact: true,
    name: "TopUp",
    component: TopUp,
  },
  {
    path: "/wallet/topup/payment/:id",
    exact: true,
    name: "TopUp Payment",
    component: TopUpPayment,
  },
  {
    path: "/wallet/topup/paymentqr/:id",
    exact: true,
    name: "TopUp Payment Qr",
    component: TopUpPaymentQr,
  },
  {
    path: "/productList/:route",
    exact: true,
    name: "ProductList",
    component: ProductList,
  },
  {
    path: "/posProductList",
    exact: true,
    name: "POSProductList",
    component: POSProductList,
  },
  {
    path: "/productDetail/:route/:productId",
    exact: true,
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    path: "/cartList",
    exact: true,
    name: "CartList",
    component: CartList,
  },
  {
    path: "/posCartList",
    exact: true,
    name: "POSCartList",
    component: POSCartList,
  },
  {
    path: "/checkOut",
    exact: true,
    name: "CheckOut",
    component: CheckOut,
  },
  // {
  //   path: "/posCheckOut",
  //   exact: true,
  //   name: "POSCheckOut",
  //   component: POSCheckOut,
  // },
  {
    path: "/stampCard",
    exact: true,
    name: "StampCard",
    component: StampCard,
  },
  {
    path: "/voucher",
    exact: true,
    name: "Voucher",
    component: Voucher,
  },
  {
    path: "/voucher/buy/:shopId",
    exact: true,
    name: "Buy Voucher",
    component: BuyVoucher,
  },
  {
    path: "/voucher/checkout/:shopId",
    exact: true,
    name: "Checkout Voucher",
    component: VoucherCheckout,
  },
  {
    path: "/voucher/payment",
    exact: true,
    name: "Voucher Payment",
    component: VoucherPayment,
  },
  {
    path: "/voucher/myVoucher",
    exact: true,
    name: "My Voucher",
    component: MyVoucher,
  },
  {
    path: "/voucher/share/:id",
    exact: true,
    name: "Share Voucher",
    component: ShareVoucher,
  },
  {
    path: "/orderSuccess",
    exact: true,
    name: "OrderSuccess",
    component: OrderSuccess,
  },
  {
    path: "/order",
    exact: true,
    name: "Order",
    component: Order,
  },
  {
    path: "/currentOrderDetail",
    exact: true,
    name: "CurrentOrderDetail",
    component: CurrentOrderDetail,
  },
  {
    path: "/completeOrderDetail",
    exact: true,
    name: "CompleteOrderDetail",
    component: CompleteOrderDetail,
  },
  {
    path: "/addressForm/:id",
    exact: true,
    name: "UserAddressForm",
    component: UserAddressForm,
  },
  {
    path: "/userProfile",
    exact: true,
    name: "UserProfile",
    component: UserProfile,
  },
  {
    path: "/userAddress",
    exact: true,
    name: "UserAddress",
    component: UserAddress,
  },
  {
    path: "/accountSetting",
    exact: true,
    name: "AccountSetting",
    component: AccountSetting,
  },
  {
    path: "/changePassword",
    exact: true,
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/changePhone",
    exact: true,
    name: "UserPhoneChange",
    component: UserPhoneChange,
  },
  {
    path: "/changeEmail",
    exact: true,
    name: "UserEmailChange",
    component: UserEmailChange,
  },
  {
    path: "/review/:orderNumber",
    exact: true,
    name: "Review",
    component: Review,
  },
  {
    path: "/reviewList",
    exact: true,
    name: "ReviewList",
    component: ReviewList,
  },
  {
    path: "/orderActivityList",
    name: "Order Activity List",
    component: OrderActivityList,
  },
  {
    path: "/orderHistoryList",
    name: "OrderHistoryList",
    component: OrderHistoryList,
  },
  {
    path: "/activityDetail/:id",
    name: "ActivityDetail",
    component: ActivityDetail,
  },
  {
    path: "/orderConfirm",
    exact: true,
    name: "Order Confirm",
    component: OrderConfirm,
  },
  {
    path: "/pos/productList/:route/:tableNo",
    name: "PosProductList",
    component: POSProductList,
  },
  {
    path: "/pos/orderConfirm",
    name: "PosOrderConfirm",
    component: PosOrderConfirm,
  },
  {
    path: "/pos/orderActivityList",
    name: "PosOrderActivityList",
    component: PosOrderActivityList,
  },
  {
    path: "/pos/activityDetail/:id/:orderNumber",
    name: "PosActivityDetail",
    component: PosActivityDetail,
  },
  {
    path: "/pos/orderHistoryList",
    name: "PosOrderHistoryList",
    component: PosOrderHistoryList,
  },
  {
    path: "/orderHistoryDetail/:id",
    name: "OrderHistoryDetail",
    component: OrderHistoryDetail,
  },
  {
    path: "/pos/orderHistoryDetail/:id/:orderNumber",
    name: "PosOrderHistoryDetail",
    component: PosOrderHistoryDetail,
  },
  {
    path: "/promotions",
    name: "PromotionList",
    component: PromotionList,
  },
  {
    path: "/shopMap",
    name: "ShopViewOnMap",
    component: ShopViewOnMap,
  },
  {
    path: "/shop/shopDetail/:route",
    name: "ShopDetail",
    component: ShopDetail,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  // {
  //   path: "/pos/posbilldetail/:id/:orderNumber",
  //   name: "PosBillDetail",
  //   component: PosBillDetail,
  // },
  {
    path: "/billDetail",
    name: "BillDetail",
    component: BillDetail,
  },
  {
    path: "/billpayment",
    name: "BillPayment",
    component: BillPayment,
  },
  {
    path: "/billpayment/:billId",
    name: "BillPayment",
    component: BillPayment,
  },
  {
    path: "/edc/detail/:billNumber",
    name: "EdcDetail",
    component: EdcDetail,
  },
  {
    path: "/rewardList/:shopId",
    name: "RewardList",
    component: RewardList,
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notification,
  },
];

export default routes;
