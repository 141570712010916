import React, { useContext } from "react";
import CommonContext from "../../../context/CommonContext";

export default function Select({
  name,
  options,
  error,
  defaultText,
  disabled = false,
  removeOptions = [],
  ...rest
}) {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;
  return (
    <React.Fragment>
      <select
        name={name}
        id={name}
        {...rest}
        className="custom-select form-control border"
        disabled={disabled}
      >
        <option value="">
          {defaultText ? defaultText : languageData.pleaseSelect}
        </option>
        {options.map((option, idx) => {
          if (removeOptions && removeOptions.length > 0) {
            const findOption = removeOptions.find(
              (fOption) => fOption.id === option.id
            );

            if (findOption === undefined)
              return (
                <option key={idx} value={option.id}>
                  {languageData[option.name]
                    ? languageData[option.name]
                    : option.name}
                </option>
              );
          } else
            return (
              <option key={idx} value={option.id}>
                {languageData[option.name]
                  ? languageData[option.name]
                  : option.name}
              </option>
            );
        })}
      </select>
      {error && <small className="text-danger">{error}</small>}
    </React.Fragment>
  );
}
