import axios from "axios";
import {
  removeLocalStorageToken,
  getLocalStorageToken,
  getLocalStorageLanguageId,
} from "./localStorage";
import config from "../config.json";

const { mainUrl, baseRoute } = config;
const DEFAULT_LANGUAGE_ID = 1;

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    //toast.error("An unexpected error occurrred.");
  } else if (error.response.status === 401 || error.response.status === 403) {
    removeLocalStorageToken();
    const url = window.location.href.toString();
    if (!url.includes("/login" || "/register")) {
      window.location = mainUrl + `${baseRoute}/login`;
    }
  } else {
    console.error("An unexpected error occurrred."); // or use your preferred way to handle this
  }

  return Promise.reject(error);
});

function setToken(token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

function removeToken() {
  axios.defaults.headers.common["Authorization"] = null;
}

function setLanguage(languageId) {
  axios.defaults.headers.common["languageId"] = languageId;
}

// Add desired headers to the defaults
axios.defaults.headers.common = {
  ...axios.defaults.headers.common,
  "Content-Type": "application/json",
  "X-Xss-Protection": "1; mode=block",
  "X-Content-Type-Options": "nosniff",
};

let token = getLocalStorageToken();
let languageId = getLocalStorageLanguageId();

setToken(token);

if (languageId != null) {
  setLanguage(parseInt(languageId));
} else {
  setLanguage(DEFAULT_LANGUAGE_ID);
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
  setLanguage,
  removeToken,
};
