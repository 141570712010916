import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// Services
import {
  getLocalStorageLanguageImage,
  getLocalStorageLanguageName,
} from "../services/localStorage";
import { setLanguage } from "../services/languageService";
// Components
import { Image } from "../components/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarth, faGlobe } from "@fortawesome/free-solid-svg-icons";

export default function HeaderLanguageDropdown({ data }) {
  const defaultName = getLocalStorageLanguageName();
  const defaultImg = getLocalStorageLanguageImage();

  return (
    <div>
      <div className="d-none d-lg-block d-md-block d-sm-none">
        <UncontrolledDropdown>
          <DropdownToggle>
            <span>
              <Image name={defaultImg} alt="Flag Image" className="flag-img" />
              <span>{defaultName}</span>
            </span>
          </DropdownToggle>
          <DropdownMenu end>
            {data.map((item) => (
              <DropdownItem key={item.id} onClick={() => setLanguage(item)}>
                <Image
                  name={item.image}
                  alt={item.name + " flag"}
                  className="flag-img"
                />
                <span>{item.name}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <div className="d-block d-lg-none d-md-none d-sm-block">
        <UncontrolledDropdown>
          <DropdownToggle>
            <FontAwesomeIcon icon={faEarth} className="header-icon me-0" />
            {/* <Image
              name={defaultImg}
              alt="language flag"
              className="header-icon"
            /> */}
          </DropdownToggle>
          <DropdownMenu end>
            {data.map((item) => (
              <DropdownItem key={item.id} onClick={() => setLanguage(item)}>
                <Image
                  name={item.image}
                  alt={item.name + " flag"}
                  className="flag-img"
                />
                <span>{item.name}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  );
}
