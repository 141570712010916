import http from "./httpService";
import config from "../config.json";

const { apiUrl } = config;

const apiEndPoint = apiUrl + "api/order/";

export function createOrder(data) {
  return http.post(apiEndPoint + "addOrder", data);
}

export function addPosOrder(data) {
  return http.post(apiEndPoint + "addPosOrder", data);
}

export function getShopOrderTypes(shopId) {
  return http.get(apiEndPoint + "getShopOrderType/" + shopId);
}

export function getShopOrderPromotion(shopId) {
  return http.get(apiEndPoint + "getShopOrderPromotion/" + shopId);
}

export function getActivityOrder(params) {
  return http.get(apiEndPoint + "getActivityOrder", params);
}

export function getOrderCancelReason() {
  return http.get(apiEndPoint + "getOrderCancelReason");
}

export function getOrderById(orderNumber) {
  return http.get(apiEndPoint + "getOrderById/" + orderNumber);
}

export function calculateDeliveryFee(data) {
  return http.get(apiEndPoint + "calculateDeliveryFee", data);
}

export function getPosActivityOrder(params) {
  return http.get(apiEndPoint + "getPosActivityOrder", params);
}

export function getPosOrderById(params) {
  return http.get(apiEndPoint + "getPosOrderById", params);
}

export function getPosOrderBillById(params) {
  return http.get(apiEndPoint + "getPosOrderBillById", params);
}

export function getHistoryOrder(params) {
  return http.get(apiEndPoint + "getHistoryOrder", params);
}

export function getPosHistoryOrder(params) {
  return http.get(apiEndPoint + "getPosHistoryOrder", params);
}

export function createOrderCancel(data) {
  return http.post(apiEndPoint + "createOrderCancel", data);
}

export function createPosOrderCancel(data) {
  return http.post(apiEndPoint + "createPosOrderCancel", data);
}

export function getOrderReview(data) {
  return http.get(apiEndPoint + "getOrderReview", data);
}

export function calculatePromotionAmount(data) {
  return http.post(apiEndPoint + "calculatePromotionAmount", data);
}

export function calcualteServiceAndTaxAmount(data) {
  return http.post(apiEndPoint + "calcualteServiceAndTaxAmount", data);
}

export function createOrderReview(data) {
  return http.post(apiEndPoint + "createOrderReview", data);
}

export function updatePosOrderProduct(data) {
  return http.post(apiEndPoint + "updatePosOrderProduct", data);
}

export function deleteProductFromPosOrder(data) {
  return http.post(apiEndPoint + "deleteProductFromPosOrder", data);
}

export function addProductToPosOrder(data) {
  return http.post(apiEndPoint + "addProductToPosOrder", data);
}

export function getPosOrderDiscountById(params) {
  return http.get(apiEndPoint + "getPosOrderDiscountById", params);
}

export function getOrderByOrderNumber(orderNumber) {
  return http.get(apiEndPoint + "getOrderByOrderNumber/" + orderNumber);
}

export function createClaimOrder(data) {
  return http.post(apiEndPoint + "createClaimOrder", data);
}

export function loadDynamicQr(id) {
  return http.get(apiEndPoint + "loadDynamicQr/" + id);
}

export function createPosOrderProductCancel(data) {
  return http.post(apiEndPoint + "createPosOrderProductCancel", data);
}

export function getOrderDataByBillNumber(param) {
  return http.get(apiEndPoint + "getOrderDataByBillNumber", param);
}
