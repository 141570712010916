import http from "../../services/httpService";
import config from "../../config.json";

const { apiUrl } = config;
const apiEndpoint = apiUrl + "api/shop/";

export function getRecommendedShop(params) {
  return http.get(apiEndpoint + "getRecommendedShop?", params);
}

export function getNearbyShop(params) {
  return http.get(apiEndpoint + "getNearbyShop?", params);
}

export function getShopByName(name) {
  return http.get(apiEndpoint + "getShopByName/" + name);
}

export function getShopHours(id) {
  return http.get(apiEndpoint + "getShopHours/" + id);
}

export function getShopReviewByShopId(id) {
  return http.get(apiEndpoint + "getShopReviewById/" + id);
}

export function addToFavouriteShop(shopId) {
  return http.post(apiEndpoint + "addToFavouriteShop/" + shopId);
}

export function removeFromFavouriteShop(shopId) {
  return http.post(apiEndpoint + "removeFromFavouriteShop/" + shopId);
}

export function subscribe(shopId) {
  return http.post(apiEndpoint + "subscribe/" + shopId);
}

export function getShops(params) {
  return http.get(apiEndpoint + "getShops", params);
}

export function getTable(params) {
  return http.get(apiEndpoint + "getTable", params);
}

export function getAllTable(params) {
  return http.get(apiEndpoint + "getAllTable", params);
}

export function getFeaturesByShopId(id) {
  return http.get(apiEndpoint + "getFeaturesByShopId/" + id);
}
