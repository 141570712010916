import http from "./httpService";
import {
  setLocalStorageLanguageId,
  setLocalStorageLanguageImage,
  setLocalStorageLanguageName,
  setLocalStorageLanguagePath,
} from "../services/localStorage";
import config from "../config.json";

const { apiUrl } = config;

const apiEndPoint = apiUrl + "api/language/";

export function getAllLanguages() {
  return http.get(apiEndPoint + "getAll");
}

export async function setLanguage(data) {
  http.setLanguage(data.id);
  await setLocalStorageLanguageId(data.id);
  await setLocalStorageLanguageImage(data.image);
  await setLocalStorageLanguageName(data.name);
  await setLocalStorageLanguagePath(data.directory);
  window.location.reload();
}

export function getLanguageData(path = "eng") {
  return http.get(apiEndPoint + "getByCode/" + path);
}
