import React, { useContext } from "react";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import animation from "../../../assets/no_data_animation.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import CommonContext from "../../../context/CommonContext";

function NoDataFoundCard({
  text,
  subText,
  isShowGoBackHome = false,
  routeText = null,
  route = "/",
  state = null,
  icon = faHome,
}) {
  const style = {
    width: 90,
    height: 90,
  };

  const commonContext = useContext(CommonContext);
  const { languageData, loadCartCount } = commonContext;

  text = text || languageData.noData;
  routeText = routeText || languageData.goBackHome;

  return (
    <div className="no-data-holder">
      <div className="no-data-icon-holder">
        <Lottie animationData={animation} style={style} loop={false} />
      </div>
      <p className="no-data-text mb-0">{text}</p>
      {subText ? <p className="no-data-sub-text">{subText}</p> : null}
      {isShowGoBackHome ? (
        <Link
          to={route}
          state={state}
          className="btn btn-sm btn-outline-primary mt-3"
        >
          <FontAwesomeIcon icon={icon} className="home-icon me-2" />
          {routeText}
        </Link>
      ) : null}
    </div>
  );
}

export default NoDataFoundCard;
