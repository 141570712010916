import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InlineIcon } from "@iconify/react";
import userCircleIcon from "@iconify/icons-mdi/user-circle-outline";
import {
  faUser,
  faSignOut,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import CommonContext from "../context/CommonContext";
// Services
import { logout } from "../components/auth/authService";
// Access data
import AccountImg from "../assets/img/account.png";
import { getLocalStorageCurrentUserName } from "../services/localStorage";

export default function HeaderAccountDropdown({ token }) {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;
  const userName = getLocalStorageCurrentUserName();

  if (!token)
    return (
      <>
        <div className="d-none d-lg-block d-md-block d-sm-none">
          <UncontrolledDropdown className="account-dropdown">
            <DropdownToggle>
              <Link to="/login">
                {/* <img
                src={AccountImg}
                alt="Account Image"
                style={{
                  width: "16px",
                  height: "auto",
                  marginRight: 10,
                  marginTop: -3,
                }}
                className="header-icon"
              /> */}
                <InlineIcon icon={userCircleIcon} className="header-icon" />
                <span>{languageData.login}</span>
              </Link>
            </DropdownToggle>
          </UncontrolledDropdown>
        </div>
        <div className="d-block d-lg-none d-md-none d-sm-block">
          <UncontrolledDropdown className="account-dropdown">
            <DropdownToggle>
              <Link to="/login">
                <InlineIcon icon={userCircleIcon} className="header-icon" />
              </Link>
            </DropdownToggle>
          </UncontrolledDropdown>
        </div>
      </>
    );
  return (
    <>
      <div className="d-none d-lg-block d-md-block d-sm-none">
        <UncontrolledDropdown className="account-dropdown">
          <DropdownToggle>
            <Link to="/userProfile">
              <InlineIcon icon={userCircleIcon} className="header-icon" />
              <span>{userName}</span>
            </Link>
            {/* <span>
              <img
                src={AccountImg}
                alt="Account Image"
                style={{
                  width: "16px",
                  height: "auto",
                  marginRight: 10,
                  marginTop: -3,
                }}
                className="header-icon"
              />
             
            </span> */}
          </DropdownToggle>
          {/* <DropdownMenu end>
            <Link to="/userProfile">
              <DropdownItem>
                <FontAwesomeIcon icon={faUser} className="icon" />
                <span>{languageData.profile}</span>
              </DropdownItem>
            </Link>
            <DropdownItem
              onClick={async () => {
                await logout();
              }}
            >
              <FontAwesomeIcon icon={faSignOut} className="icon" />
              <span>{languageData.logout}</span>
            </DropdownItem>
          </DropdownMenu> */}
        </UncontrolledDropdown>
      </div>
      <div className="d-block d-lg-none d-md-none d-sm-block">
        <UncontrolledDropdown className="account-dropdown">
          <DropdownToggle>
            <FontAwesomeIcon icon={faUserCircle} className="header-icon" />
            {/* <span>
              <img
                src={AccountImg}
                alt="Account Image"
                style={{
                  width: "16px",
                  height: "auto",
                  marginRight: 10,
                  marginTop: -3,
                }}
                className="header-icon"
              />
             
            </span> */}
          </DropdownToggle>
          <DropdownMenu end>
            <Link to="/userProfile">
              <DropdownItem>
                <FontAwesomeIcon icon={faUser} className="icon" />
                <span>{languageData.profile}</span>
              </DropdownItem>
            </Link>
            <DropdownItem
              onClick={async () => {
                await logout();
              }}
            >
              <FontAwesomeIcon icon={faSignOut} className="icon" />
              <span>{languageData.logout}</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </>
  );
}
