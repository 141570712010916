const config = {
  apiKey: "AIzaSyB_6oO2TUvGVVfeLumZz85wO8jsIkwRAW0",
  authDomain: "master-works-387104.firebaseapp.com",
  projectId: "master-works-387104",
  storageBucket: "master-works-387104.appspot.com",
  messagingSenderId: "870080560951",
  appId: "1:870080560951:web:a79e4318da3266e56564b2",
  measurementId: "G-01LETK1MTV",
};

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error(
      "No Firebase configuration object provided." +
        "\n" +
        "Add your web app's configuration object to firebase-config.js"
    );
  } else {
    return config;
  }
}

const vapidKey =
  "BMlqxRbYyO8CVmYG_kJnEMnRtWu2c-mTvblL2KOFghr5-ZVzZDqNlNoqRnayiKe86XBivdBikh7obup-L100OS0";

export function getVapidKey() {
  //return "BNB5FLvPXu7nffvJ5scLciL9XMi-zW9fPk00McKQfJTiqK_ccMzLgwmoprvqBYBEvZ3GVBCT8NZI7lOzmm9I1kU";
  return vapidKey;
}
