import React, { useEffect } from "react";
import Logo from "../../../assets/img/sample-img.png";
import BannerSampleImg from "../../../assets/img/empty-banner.png"; //
// Config Data
import config from "../../../config.json";
const { imageDisplayUrl } = config;

export default function Image({
  name,
  direct,
  banner,
  alt = "image",
  ...rest
}) {
  const [imageName, setImageName] = React.useState(null);

  useEffect(() => {
    setImageName(name);
  }, [name]);

  const handleError = () =>
    setImageName((imageName) => {
      imageName = null;
      return imageName;
    });

  return (
    <img
      src={
        direct
          ? imageName || imageDisplayUrl + imageName
          : imageDisplayUrl + imageName
      }
      onError={(e) => {
        e.target.onerror = handleError();
        e.target.src = banner ? BannerSampleImg : Logo;
      }}
      alt={alt}
      {...rest}
    />
  );
}
