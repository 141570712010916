import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { InlineIcon } from "@iconify/react";
import closeIcon from "@iconify/icons-mdi/close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { CommonContext } from "./../../context/CommonContext";
import CartContext from "../../context/CartContext";
// Services
import {
  getLocalStorageOrderTypeInfo,
  getLocalStorageShopId,
  getLocalStorageShopRoute,
  getLocalStorageShopTypes,
  setLocalStorageShopId,
  setLocalStorageShopRoute,
} from "../../services/localStorage";
// Components
import { Image, Loading } from "../common";
import { numberWithCommas } from "./../../utils/number";
// Helper
import { getCalculateDiscount } from "../product/helper";
// styles
import "../style/product-list.css";
import "../style/product-detail.css";
import "../style/order-confirm.css";
import "../style/cart-list.css";
// Assets
import NoDataFoundCard from "../common/noData/noDataFound";
import IconDiscount from "../../assets/img/icon-discount.png";
/// Config Data
import config from "../../config.json";
import _ from "lodash";
import CartProductEditModal from "../product/cartProductEditModal";
import { NotificationManager } from "react-notifications";
import DiscountProductModal from "../product/discountProductModal";

const { CURRENCY_CODE, DELIVERY, POS, imageDisplayUrl } = config;

export default function CartModal({ isShow = false, setIsShow }) {
  const commonContext = useContext(CommonContext);
  const { languageData, socketId } = commonContext;
  const navigate = useNavigate();
  const cartContext = useContext(CartContext);
  const { cart, changeCartData } = cartContext;

  const [products, setProducts] = useState([]);
  const [discountProducts, setDiscountProducts] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [isDeliveryOrderType, setIsDeliveryOrderType] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [currentCartData, setCurrentCartData] = useState([]);
  const [giveAwayItems, setGiveAwayItems] = useState([]);
  const [isShowProductEditModal, setIsShowProductEditModal] = useState(false);
  const [editProductData, setEditProductData] = useState({});
  const [giveAwayDiscounts, setGiveAwayDiscounts] = useState([]);
  const [currentGiveAwayDiscount, setCurrentGiveAwayDiscount] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [shopRoute, setShopRoute] = useState(null);
  const [shopTypes, setShopTypes] = useState(null);
  const [submitLoad, setSubmitLoad] = useState(false);
  const orderType = getLocalStorageOrderTypeInfo()?.value || null;
  const orderTypeId = getLocalStorageOrderTypeInfo()?.id || 1;
  let currentPathName = useLocation()?.pathname;
  const isShopCurrentPath = currentPathName.includes(`/productList/`);
  const isCheckoutCurrentPath = currentPathName.includes("/orderConfirm");

  useEffect(() => {
    (async () => {
      if (isShow) {
        setIsLoad(true);
        setProducts([]);
        setDiscountProducts([]);
        // let gDAta = await cart.filter((g) => g.isDiscountProduct === true);
        // setGiveAwayItems(gDAta);
        // let cData = await cart.filter((c) => c.isDiscountProduct === false);
        // setCartData(cart);
        setIsLoad(false);
      }
    })();
  }, [isShow]);

  useEffect(() => {
    loadCartData();
  }, [cart]);

  useEffect(() => {
    loadLocalCartData(shopId);
  }, [cartData, shopId]);

  useEffect(() => {
    if (giveAwayDiscounts.length > 0) {
      setCurrentGiveAwayDiscount(giveAwayDiscounts[0]);
    } else {
      setCurrentGiveAwayDiscount(null);
    }
  }, [giveAwayDiscounts]);

  const loadCartData = async () => {
    setIsLoad(true);
    try {
      let localCart = await _.cloneDeep(cart);
      for (let c of localCart) {
        let localCartData = c.cartProducts.filter(
          (item) => !item.isDiscountProduct
        );
        let localGiveawayData = c.cartProducts.filter(
          (item) => item.isDiscountProduct
        );
        let tTotalPrice = 0;
        let tTotalGivenPoint = 0;
        let tTotalQuantity = 0;

        const discountInfo = await getCalculateDiscount(
          c.shopId,
          orderType,
          localCartData,
          localGiveawayData,
          0,
          null,
          0,
          orderTypeId
        );

        let tTotalDiscountPrice = 0;
        if (discountInfo) {
          await discountInfo.otherDiscounts.map(async (discount) => {
            let discountAmount = discount.amount || 0;
            tTotalDiscountPrice += discountAmount;
          });
          c.serviceFee = discountInfo.serviceFee;
          c.taxAmount = discountInfo.taxAmount;
        }
        localCartData.map((item) => {
          tTotalPrice += item.selectedPrice * item.selectedQuantity;
          tTotalQuantity += item.selectedQuantity;
        });
        c.totalPrice = tTotalPrice;
        c.totalDiscountPrice = tTotalDiscountPrice;
      }
      setCartData(localCart);
      setIsLoad(false);
    } catch (error) {
      setIsLoad(false);
      console.log("cart modal load cart error", error);
    }
  };

  const toggle = () => {
    setIsShow(!isShow);
  };

  const removeFromCart = async (itemIdx, shopId) => {
    try {
      let currentCartData = _.cloneDeep(cart);
      let currentCart = await currentCartData.find((c) => c.shopId == shopId);
      if (currentCart) {
        let cartProducts = currentCart.cartProducts;
        cartProducts.splice(itemIdx, 1);
        await changeCartData(cartProducts, shopId);
      }
      setCartData(currentCartData);
    } catch (error) {
      console.log("error is ", error);
      NotificationManager.error(
        "remove from cart failed",
        languageData.serverError
      );
    }
  };

  const changeQuantity = async (itemIdx, shopId, quantity) => {
    console.log("QUAntity is ", quantity);
    try {
      let currentCartData = _.cloneDeep(cart);
      let currentCart = await currentCartData.find((c) => c.shopId == shopId);
      if (currentCart) {
        if (currentCart.cartProducts[itemIdx]) {
          if (currentCart.cartProducts[itemIdx].maxQuantity >= quantity) {
            delete currentCart.cartProducts[itemIdx].isOutOfStock;
          }
          currentCart.cartProducts[itemIdx].selectedQuantity = quantity;
          await changeCartData(currentCart.cartProducts, shopId);
        }
        setCartData(currentCartData);
      }
    } catch (error) {
      console.log("error is ", error);
      NotificationManager.error(
        "remove from cart failed",
        languageData.serverError
      );
    }
  };

  const handleOrderConfirm = async (shopData) => {
    await setLocalStorageShopId(shopData.id);
    await setLocalStorageShopRoute(shopData.route);
    toggle();
    navigate("/orderConfirm");
  };

  const handleChangeCartData = async (cData) => {
    let currentCartData = _.cloneDeep(cart);
    let currentCart = await currentCartData.find((c) => c.shopId == shopId);
    if (currentCart) {
      currentCart.cartProducts = cData;
      await changeCartData(cData, shopId);
    }
    setCartData(currentCartData);
  };

  const loadLocalCartData = async (shopId) => {
    let currentShopCartData = await cartData.find((c) => c.shopId == shopId);
    if (currentShopCartData) {
      let currentCartData = await currentShopCartData.cartProducts.filter(
        (c) => c.isDiscountProduct === false
      );
      setCurrentCartData(currentCartData);
    }
  };

  return (
    <Modal
      isOpen={isShow}
      className="modal-dialog-centered product-list_wrapper cart-modal product-attr-modal"
      id="modal-dialog"
      toggle={toggle}
    >
      <ModalHeader className="justify-content-end pb-2">
        {/* Cart Items */}
        <div onClick={toggle} className="modal-btn-close">
          <InlineIcon icon={closeIcon} className="close-icon" />
        </div>
      </ModalHeader>
      {/* <ModalHeader className="d-flex justify-content-end" toggle={toggle} /> */}
      <>
        {isLoad ? (
          <Loading isLoad={isLoad} />
        ) : (
          <>
            {cartData.length > 0 ? (
              <>
                <ModalBody style={{ height: "400px" }}>
                  {cartData.map((c, cIndex) => {
                    if (c.cartProducts.length > 0) {
                      return (
                        <div key={cIndex} className="border-card mt-2">
                          <div className="row">
                            <div className="col-8">
                              <div className="d-flex flex-row mb-2">
                                <div>
                                  <img
                                    src={imageDisplayUrl + c.shopData.shopLogo}
                                    className="shop-logo"
                                    alt=""
                                  />
                                </div>
                                <div className="shop--name">
                                  <span>{c.shopData.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {c.cartProducts.map((item, itemIdx) => {
                            if (!item.isDiscountProduct) {
                              const product = item.productData;
                              return (
                                <div
                                  key={itemIdx}
                                  style={{ position: "relative" }}
                                  className="border-card mt-2"
                                >
                                  <div className="cart-list-item">
                                    <div className="d-flex justify-content-between">
                                      <div className="product-info_holder ">
                                        <div className="product-img_holder">
                                          <Image
                                            name={product.image}
                                            alt="Shop Image"
                                          />
                                        </div>
                                        <div className="product-item_holder">
                                          <div className="item-name mb-1">
                                            <span>{product.productName}</span>
                                          </div>
                                          <div className="d-flex flex-wrap">
                                            {item.selectedComboProducts?.map(
                                              (combo, comboIdx) => {
                                                return (
                                                  <span
                                                    key={comboIdx}
                                                    className="item-attr"
                                                  >
                                                    + {combo.productName}{" "}
                                                    <span>
                                                      x {combo.quantity}
                                                    </span>
                                                  </span>
                                                );
                                              }
                                            )}
                                          </div>
                                          <div className="d-flex flex-wrap">
                                            {item.selectedAttributes?.map(
                                              (attribute, attIdx) => (
                                                <span
                                                  key={attIdx}
                                                  className="item-attr"
                                                >
                                                  <span>{attribute.name}</span>
                                                  <span className="mx-1">
                                                    :
                                                  </span>
                                                  {attribute.options?.map(
                                                    (option, optIndx) => {
                                                      return (
                                                        <b key={optIndx}>
                                                          {option.optionName}
                                                          {optIndx + 1 <
                                                          attribute.options
                                                            .length
                                                            ? ", "
                                                            : null}
                                                        </b>
                                                      );
                                                    }
                                                  )}
                                                </span>
                                              )
                                            )}
                                          </div>
                                          <div className="d-flex flex-wrap">
                                            <span className="qty-wrap">
                                              <span>
                                                {languageData.quantity}
                                              </span>
                                              <span className="mx-1">:</span>
                                              <span className="fw-bold">
                                                {item.selectedQuantity}
                                              </span>
                                            </span>
                                          </div>
                                          {item.productData.isDiscountItem &&
                                          item.selectedPrice <
                                            item.selectedOriginalPrice ? (
                                            <div className="mt-1">
                                              <span className="item-price">
                                                {numberWithCommas(
                                                  item.selectedPrice
                                                )}{" "}
                                                {languageData[CURRENCY_CODE]}
                                              </span>
                                              <span className="discount-price">
                                                <del>
                                                  {numberWithCommas(
                                                    item.selectedOriginalPrice
                                                  )}{" "}
                                                  {languageData[CURRENCY_CODE]}
                                                </del>
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="mt-1">
                                              <span className="item-price">
                                                {numberWithCommas(
                                                  item.selectedOriginalPrice ||
                                                    item.selectedPrice
                                                )}{" "}
                                                {languageData[CURRENCY_CODE]}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        {product.givenPoint > 0 ? (
                                          <span className="reward-point_wrap">
                                            {numberWithCommas(
                                              product.givenPoint *
                                                item.selectedQuantity
                                            )}{" "}
                                            {languageData.pts}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    {item.isDiscountItem && (
                                      <div>
                                        <div
                                          className="discount-wrap"
                                          style={{
                                            position: "absolute",
                                            left: "13px",
                                            top: "11px",
                                          }}
                                        >
                                          <img
                                            src={IconDiscount}
                                            className="discount-img"
                                          />
                                          {/* <span>10% Off</span> */}
                                        </div>
                                      </div>
                                    )}
                                    <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                                      <div className="qty-input">
                                        <div className="input-group">
                                          <span
                                            className="input-group-text me-1"
                                            onClick={() => {
                                              if (
                                                (item.selectedQuantity > 1 &&
                                                  c.shopTypes.isShop &&
                                                  item.maxQuantity >=
                                                    item.selectedQuantity -
                                                      1) ||
                                                !c.shopTypes.isShop
                                              ) {
                                                let quantity =
                                                  item.selectedQuantity - 1;
                                                changeQuantity(
                                                  itemIdx,
                                                  c.shopId,
                                                  quantity
                                                );
                                              }
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMinus}
                                              className="icon"
                                            />
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={item.selectedQuantity}
                                            readOnly
                                          />
                                          <span
                                            className="input-group-text"
                                            onClick={() => {
                                              if (
                                                (c.shopTypes.isShop &&
                                                  item.maxQuantity >=
                                                    item.selectedQuantity +
                                                      1) ||
                                                !c.shopTypes.isShop
                                              ) {
                                                let quantity =
                                                  item.selectedQuantity + 1;
                                                changeQuantity(
                                                  itemIdx,
                                                  c.shopId,
                                                  quantity
                                                );
                                              }
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faPlus}
                                              className="icon"
                                            />
                                          </span>
                                        </div>
                                      </div>

                                      <div className="d-flex align-items-center">
                                        <div className="me-2">
                                          <span
                                            className="primary-btn"
                                            onClick={() => {
                                              setShopId(c.shopId);
                                              setShopTypes(c.shopTypes);
                                              setEditProductData({
                                                ...item,
                                                index: itemIdx,
                                              });
                                              setIsShowProductEditModal(true);
                                            }}
                                          >
                                            {languageData.edit}
                                          </span>
                                        </div>
                                        <div>
                                          <span
                                            className="delete-btn"
                                            onClick={() => {
                                              setShopId(c.shopId);
                                              removeFromCart(itemIdx, c.shopId);
                                            }}
                                          >
                                            {languageData.remove}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                          {/* For Discount Products */}
                          {c.cartProducts.map((item, itemIdx) => {
                            if (item.isDiscountProduct) {
                              const product = item.productData;
                              return (
                                <div
                                  key={itemIdx}
                                  className="border-card mt-2"
                                  style={{ position: "relative" }}
                                >
                                  <div className="cart-list-item">
                                    <div className="d-flex justify-content-between">
                                      <div className="product-info_holder ">
                                        <div className="product-img_holder">
                                          <Image
                                            name={product.image}
                                            alt="Shop Image"
                                          />
                                        </div>
                                        <div className="product-item_holder">
                                          <div className="item-name mb-1">
                                            <span>{product.productName}</span>
                                          </div>
                                          <div className="d-flex flex-wrap">
                                            {item.selectedComboProducts?.map(
                                              (combo, comboIdx) => {
                                                return (
                                                  <span
                                                    key={comboIdx}
                                                    className="item-attr"
                                                  >
                                                    + {combo.productName}{" "}
                                                    <span>
                                                      x {combo.quantity}
                                                    </span>
                                                  </span>
                                                );
                                              }
                                            )}
                                          </div>
                                          <div className="d-flex flex-wrap">
                                            {item.selectedAttributes?.map(
                                              (attribute, attIdx) => (
                                                <span
                                                  key={attIdx}
                                                  className="item-attr"
                                                >
                                                  <span>{attribute.name}</span>
                                                  <span className="mx-1">
                                                    :
                                                  </span>
                                                  {attribute.options?.map(
                                                    (option, optIndx) => {
                                                      return (
                                                        <b key={optIndx}>
                                                          {option.optionName}
                                                          {optIndx + 1 <
                                                          attribute.options
                                                            .length
                                                            ? ", "
                                                            : null}
                                                        </b>
                                                      );
                                                    }
                                                  )}
                                                </span>
                                              )
                                            )}
                                          </div>
                                          <div className="d-flex flex-wrap">
                                            <span className="qty-wrap">
                                              <span>
                                                {languageData.quantity}
                                              </span>
                                              <span className="mx-1">:</span>
                                              <span className="fw-bold">
                                                {item.selectedQuantity}
                                              </span>
                                            </span>
                                          </div>
                                          <div className="mt-1">
                                            <span className="small-text text-primary fw-bold">
                                              {languageData.free}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}

                          <div
                            style={{ position: "relative" }}
                            className="border-card mt-2 mb-2"
                          >
                            <div className="cart-list-item">
                              <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                <span className="small-text">
                                  {languageData.subTotal}
                                </span>
                                <span className="small-text fw-bold">
                                  {numberWithCommas(c.totalPrice)}{" "}
                                  {languageData[CURRENCY_CODE]}
                                </span>
                              </div>
                            </div>
                            {c.totalDiscountPrice > 0 && (
                              <div className="cart-list-item">
                                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                  <span className="small-text">
                                    {languageData.discountAmount}
                                  </span>
                                  <span className="small-text fw-bold text-danger">
                                    -{numberWithCommas(c.totalDiscountPrice)}{" "}
                                    {languageData[CURRENCY_CODE]}
                                  </span>
                                </div>
                              </div>
                            )}
                            {c.serviceFee > 0 && (
                              <div className="cart-list-item">
                                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                  <span className="small-text">
                                    {languageData.serviceCharges}
                                  </span>
                                  <span className="small-text fw-bold">
                                    {numberWithCommas(c.serviceFee)}{" "}
                                    {languageData[CURRENCY_CODE]}
                                  </span>
                                </div>
                              </div>
                            )}
                            {c.taxAmount > 0 && (
                              <div className="cart-list-item">
                                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                  <span className="small-text">
                                    {languageData.tax}
                                  </span>
                                  <span className="small-text fw-bold">
                                    {numberWithCommas(c.taxAmount)}{" "}
                                    {languageData[CURRENCY_CODE]}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="divider-line"></div>
                            <div className="cart-list-item">
                              <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                <span className="small-text">
                                  {languageData.total}
                                </span>
                                <span className="small-text fw-bold">
                                  {numberWithCommas(
                                    c.totalPrice +
                                      c.serviceFee +
                                      c.taxAmount -
                                      c.totalDiscountPrice
                                  )}{" "}
                                  {languageData[CURRENCY_CODE]}
                                </span>
                              </div>
                              {isDeliveryOrderType && (
                                <span className="text-warning">
                                  {languageData.not}:{" "}
                                  {
                                    languageData.ifDeliveryOrderTypeWillAddPriceMessage
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                          {!isCheckoutCurrentPath && (
                            <div
                              onClick={() => handleOrderConfirm(c.shopData)}
                              className="btn btn-primary w-100 mb-2"
                            >
                              <span>{languageData.checkoutNow}</span>
                            </div>
                          )}
                          {!isShopCurrentPath && (
                            <Link
                              to={`/productList/${c.shopData.route}`}
                              className="btn btn-outline-primary w-100 mb-2"
                              onClick={toggle}
                            >
                              <span>{languageData.goToShop}</span>
                            </Link>
                          )}
                        </div>
                      );
                    }
                  })}
                </ModalBody>
              </>
            ) : (
              <div className="border-card m-3">
                <NoDataFoundCard
                  text={languageData.noDataInShoppingCart}
                  className="mb-0"
                />
              </div>
            )}
          </>
        )}
      </>
      <CartProductEditModal
        isShow={isShowProductEditModal}
        setIsShow={setIsShowProductEditModal}
        data={editProductData}
        shopTypes={shopTypes}
        changeCartData={handleChangeCartData}
        isPos={orderType == POS}
        shopId={shopId}
        setCartData={async (data) => {
          const result = await changeCartData(data, shopId);
          if (result) {
            setCartData(data);
          }
        }}
        cartData={currentCartData}
      />
      {currentGiveAwayDiscount !== null && (
        <DiscountProductModal
          isShow={true}
          data={currentGiveAwayDiscount.discountProducts}
          maxQuantity={currentGiveAwayDiscount.maxQuantity}
          productIds={currentGiveAwayDiscount.productIds}
          shopTypes={shopTypes}
          pairedProducts={currentGiveAwayDiscount.pairedProducts}
          customSelectedQuantity={
            currentGiveAwayDiscount.customSelectedQuantity
          }
          shopId={shopId}
          setIsShow={() => {}}
          discountId={currentGiveAwayDiscount.discountId}
          giveAwayDiscounts={giveAwayDiscounts}
          setGiveAwayDiscounts={setGiveAwayDiscounts}
          currentGiveAwayDiscountIndex={0}
          cartData={giveAwayItems}
          setCartData={setGiveAwayItems}
          setLoadingMessage={() => {}}
          setIsLoadAddToCart={setSubmitLoad}
          title={currentGiveAwayDiscount.title}
        />
      )}
    </Modal>
  );
}
