import React, { useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faSignOut,
  faClose,
  faHome,
  faLanguage,
  faList,
  faBars,
  faHistory,
  faSearch,
  faUser,
  faClipboard,
  faFileClipboard,
  faClipboardList,
  faGlobe,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import OffCanvas from "react-aria-offcanvas";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { InlineIcon } from "@iconify/react";
import fileIcon from "@iconify/icons-mdi/file-outline";
import accountOutlineIcon from "@iconify/icons-mdi/account-outline";
import historyIcon from "@iconify/icons-mdi/history";
import logoutIcon from "@iconify/icons-mdi/logout";
import logInIcon from "@iconify/icons-mdi/login";
import homeIcon from "@iconify/icons-mdi/home";
import securityIcon from "@iconify/icons-mdi/security";
import bellIcon from "@iconify/icons-mdi/bell-outline";
import shoppingCartIcon from "@iconify/icons-mdi/shopping-cart-outline";
import searchIcon from "@iconify/icons-mdi/search";

import CommonContext from "../context/CommonContext";
// Services
import { getToken, logout } from "../components/auth/authService";
// Component
import HeaderLanguageDropdown from "./HeaderLanguageDropdown";
import HeaderAccountDropdown from "./HeaderAccountDropdown";
// Access data

import UserIcon from "../assets/img/user.png";
import HomeIcon from "../assets/img/m-home.png";
import OrderIcon from "../assets/img/order.png";
import HistoryIcon from "../assets/img/history.png";
import LoginIcon from "../assets/img/login.png";
import LogOutIcon from "../assets/img/logout.png";
import POSOrderIcon from "../assets/img/pos-order.png";
import POSHistoryIcon from "../assets/img/pos-history.png";

// Logo
import Logo from "../assets/img/yoyo_logo.png";
import menuLogo from "../assets/img/yoyo_logo_white.png";
import CartModal from "../components/cart/cartModal";

const offCanvasMenuStyles = {
  content: {
    background: "rgb(255,255,255)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.25)",
    zIndex: "99999",
  },
  menuButton: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "23px",
    padding: 0,
    marginTop: -4,
  },
  icon: {
    color: "red",
  },
  menuBtnIcon: {
    color: "#fff",
    fontSize: 18,
  },
  menuButtonClose: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    color: "#217CDC",
    fontWeight: "400",
    position: "absolute",
    top: 10,
    right: 0,
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    padding: 12,
    fontSize: 18,
  },
  OffCanvasMenuList: {
    listStyle: "none",
    paddingLeft: 0,
  },
};

export default function Header({ languages }) {
  const navigate = useNavigate();
  const token = getToken();
  const commonContext = useContext(CommonContext);
  const { cartCount, notiCount, languageData } = commonContext;
  let currentPathName = useLocation()?.pathname;

  const [isOpen, setIsOpen] = useState(false);
  const [isShowCart, setIsShowCart] = useState(false);

  const close = () => {
    setIsOpen(false);
  };

  // Search Modal
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div>
      {/* HEADER STARTS */}
      <header className="fixed-top">
        <nav className="navbar navbar-expand-lg navbar-header">
          <div className="container">
            <div className="navbar-brand">
              <button
                aria-label="Menu"
                aria-controls="menu"
                aria-expanded={isOpen}
                onClick={() => setIsOpen(true)}
                style={offCanvasMenuStyles.menuButton}
              >
                <FontAwesomeIcon icon={faBars} className="menu-icon" />
              </button>
              <Link to={token ? "/home" : "/"}>
                <img src={Logo} className="logo-img" alt="Logo Image" />
              </Link>
            </div>
            <ul className="header-right ms-auto">
              <li className="nav-item">
                <div className="nav-link search">
                  <InlineIcon
                    onClick={() => navigate("/search")}
                    icon={searchIcon}
                    className="icon"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link">
                  <img src={ScanImg} alt="Scan Image" className="header-icon" />
                </Link>
              </li>*/}
              {token && (
                <li className="nav-item header-cart-count">
                  <span
                    className="nav-link"
                    onClick={() => navigate("/notification")}
                  >
                    <InlineIcon icon={bellIcon} className="icon" />
                    <span className="small badge bg-danger">{notiCount}</span>
                  </span>
                </li>
              )}

              {/* {token && !currentPathName.includes("pos") && ( */}
              <li className="nav-item header-cart-count">
                <span
                  // to="/cartList"
                  className="nav-link"
                  onClick={() => setIsShowCart(true)}
                >
                  <InlineIcon icon={shoppingCartIcon} className="icon" />
                  <span className="small badge bg-primary">{cartCount}</span>
                </span>
                {/* For Cart Modal */}
                {isShowCart && (
                  <CartModal isShow={isShowCart} setIsShow={setIsShowCart} />
                )}
              </li>
              {/* )} */}

              <li className="nav-item ">
                <HeaderLanguageDropdown data={languages} />
              </li>

              <li className="nav-item d-none">
                <HeaderAccountDropdown token={token} />
              </li>

              {/* Mobile Account Dropdown */}
              {/* <li className="nav-item d-block d-lg-block d-md-block d-sm-none">
                <HeaderAccountDropdown />
              </li> */}
            </ul>
          </div>
        </nav>
      </header>

      {/* Search Modal */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-dialog-centered search-modal"
      >
        <ModalHeader>
          <span>Search</span>
          <div className="close-text" onClick={toggle}>
            <span>Close</span>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="search-box_wrapper">
            <input
              type="text"
              placeholder={languageData.searchShowsOrRestaurants}
              className="form-control"
              // onChange={(e) => setSearch(e.target.value)}
              // onKeyUp={(e) => {
              //   if (e.key === "Enter" || e.keyCode === 13) {
              //     navigate("/shopList", { state: { name: search } });
              //   }
              // }}
            />
            <FontAwesomeIcon icon={faSearch} className="icon" />
          </div>
          <p className="sub-title">Recent search</p>
          <div className="d-flex flex-row justify-content-between align-item-center mt-3">
            <div>
              <FontAwesomeIcon icon={faHistory} className="history-icon me-3" />
              <span className="shop-name">KFC sanchaung</span>
            </div>
            <FontAwesomeIcon icon={faClose} className="close-icon" />
          </div>
          <div className="d-flex flex-row justify-content-between align-item-center mt-3">
            <div>
              <FontAwesomeIcon icon={faHistory} className="history-icon me-3" />
              <span className="shop-name">chris bar</span>
            </div>
            <FontAwesomeIcon icon={faClose} className="close-icon" />
          </div>
        </ModalBody>
      </Modal>
      <OffCanvas
        isOpen={isOpen}
        onClose={close}
        labelledby="menu-button"
        height="100%"
        position="left"
        style={offCanvasMenuStyles}
      >
        <div className="menu-header" onClick={close}>
          <Link to={token ? "/home" : "/"} className="menu-header-logo">
            <img src={menuLogo} className="menu-logo" alt="Logo Image" />
          </Link>
        </div>
        {/* <button onClick={close} style={offCanvasMenuStyles.menuButtonClose}>
          <FontAwesomeIcon icon={faClose} />
        </button> */}

        <nav id="menu">
          <ul style={offCanvasMenuStyles.OffCanvasMenuList}>
            <li>
              <Link to="/home" className="offcanvas-menu_link" onClick={close}>
                <InlineIcon icon={homeIcon} className="icon me-3" />
                <span>{languageData.home}</span>
              </Link>
            </li>
            {token && !currentPathName.includes("pos") && (
              <>
                <li>
                  <Link
                    to="/userProfile"
                    className="offcanvas-menu_link"
                    onClick={close}
                  >
                    <InlineIcon
                      icon={accountOutlineIcon}
                      className="icon me-3"
                    />
                    <span>{languageData.profile}</span>
                  </Link>
                </li>
              </>
            )}
            {token && (
              <>
                <li>
                  <Link
                    to="/voucher/myVoucher"
                    className="offcanvas-menu_link"
                    onClick={close}
                  >
                    <InlineIcon
                      icon={accountOutlineIcon}
                      className="icon me-3"
                    />
                    <span>{languageData.myVouchers}</span>
                  </Link>
                </li>
              </>
            )}
            {/* {currentPathName.includes("pos") && (
              <>
                <li>
                  <Link
                    to="/home"
                    className="offcanvas-menu_link"
                    onClick={close}
                  >
                    <InlineIcon icon={homeIcon} className="icon me-3" />
                    <span>{languageData.home}</span>
                  </Link>
                </li>
              </>
            )} */}
            <li>
              <Link
                to={token ? "/orderActivityList" : "/pos/orderActivityList"}
                className="offcanvas-menu_link"
                onClick={close}
              >
                <InlineIcon icon={historyIcon} className="icon me-3" />
                <span>{languageData.menuOrders}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/home" className="offcanvas-menu_link" onClick={close}>
                <FontAwesomeIcon icon={faGlobe} className="icon me-3" />
                <span>{languageData.changeLanguage}</span>
              </Link>
            </li> */}

            {token ? (
              <li>
                <div
                  onClick={async () => {
                    await logout();
                  }}
                  className="offcanvas-menu_link"
                >
                  <InlineIcon icon={logoutIcon} className="icon me-3" />
                  <span>{languageData.signOut}</span>
                </div>
              </li>
            ) : (
              <>
                <li>
                  <Link
                    to="/login"
                    className="offcanvas-menu_link"
                    onClick={close}
                  >
                    <InlineIcon icon={logInIcon} className="icon me-3" />
                    <span>{languageData.signIn}</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </OffCanvas>
    </div>
  );
}
