import config from "../config.json";
const { baseRoute } = config;

if (
  window.location.pathname.toLowerCase().startsWith(baseRoute.toLowerCase())
) {
  const newPath = window.location.pathname.replace(
    new RegExp(`^${baseRoute}`, "i"),
    baseRoute
  );
  window.history.replaceState(null, "", newPath);
} else {
  if (!window.location.pathname.startsWith(baseRoute)) {
    window.history.replaceState(
      null,
      "",
      `${baseRoute}${window.location.pathname}`
    );
  }
}
