import React, { useEffect, useState } from "react";
import moment from "moment";

export default function CustomBirthDateSelect({
  name = "custom-birth-date",
  value = new Date(),
  startYear = new Date().getFullYear(),
  yearOfRance = 100,
  onChange,
}) {
  const currentYear = new Date().getFullYear();

  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);

  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    loadData();
  }, [yearOfRance]);

  useEffect(() => {
    if (months.length > 0) {
      loadValue(months);
    }
  }, [months]);

  useEffect(() => {
    loadDays(month, year);
  }, [month, year]);

  useEffect(() => {
    if ((day, month, year)) {
      const monthNumber = getMonthNumber(month); // get month no

      if (monthNumber > -1) {
        const dateString = `${day}-${monthNumber}-${year}`;
        const dateParts = dateString.split("-");
        const dYear = parseInt(dateParts[2], 10);
        const dMonth = parseInt(dateParts[1], 10) - 1; // Months are zero-indexed
        const dDay = parseInt(dateParts[0], 10);
        const newDate = new Date(dYear, dMonth, dDay);

        onChange(newDate);
      }
    }
  }, [day, month, year]);

  const loadValue = (months) => {
    let defaultValue = value;

    const dYear = new Date(defaultValue).getFullYear();
    const dMonth = new Date(defaultValue).getMonth();
    const dDay = new Date(defaultValue).getDate();

    setDay(dDay);
    setMonth(months[dMonth]);
    setYear(dYear);
  };

  const loadData = () => {
    let lastYear = currentYear - yearOfRance;
    if (startYear !== currentYear) {
      lastYear = startYear - yearOfRance;
    }

    let tYears = [];
    let tMonths = [];
    for (let y = startYear; y >= lastYear; y--) {
      tYears.push(y);
    }
    tMonths = moment.months();

    setMonths(tMonths);
    setYears(tYears);
    setIsLoad(false);
  };

  // Month name to Number change
  const getMonthNumber = (name) => {
    const findIndex = months.findIndex((item) => item === name);

    return findIndex > -1 ? findIndex + 1 : findIndex;
  };

  const loadDays = async (month, year) => {
    const monthNumber = await getMonthNumber(month);
    if (monthNumber > -1) {
      let daysInMonth = moment(
        year + "-" + monthNumber,
        "YYYY-M"
      ).daysInMonth();

      let tempDays = [];
      for (let d = 1; d <= daysInMonth; d++) {
        tempDays.push(d);
      }
      setDays(tempDays);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "day") {
      setDay(value);
    } else if (name === "month") {
      setMonth(value);
    } else if (name === "year") {
      setYear(value);
    }
  };

  if (isLoad) return "Loading...";
  return (
    <React.Fragment key={name}>
      <div className="row gx-3">
        <div className="col-4">
          <Select
            name="day"
            value={day}
            options={days}
            defaultText="Day"
            onChange={handleChange}
          />
        </div>
        <div className="col-4">
          <Select
            name="month"
            value={month}
            options={months}
            defaultText="Month"
            onChange={handleChange}
          />
        </div>
        <div className="col-4">
          <Select
            name="year"
            value={year}
            options={years}
            defaultText="Year"
            onChange={handleChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export const Select = ({
  name,
  options,
  defaultText = "Please Select",
  ...rest
}) => {
  return (
    <select
      id={name}
      name={name}
      className="custom-select form-control border"
      {...rest}
    >
      {options.map((option, idx) => {
        return (
          <option key={idx} value={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};
