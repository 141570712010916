import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "../routes";
import Header from "./Header";
import Footer from "./Footer";

export default function Layout(props) {
  const { languages } = props;

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <Suspense fallback={loading}>
      <Header languages={languages} />
      <main className="content-wrapper">
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" element={<Navigate to="/page404" replace />} />
            {routes.map((route) => {
              return (
                route.component && (
                  <Route
                    key={route.name}
                    path={route.path}
                    element={<route.component {...props} />}
                  />
                )
              );
            })}
          </Routes>
        </Suspense>
      </main>
      <Footer />
    </Suspense>
  );
}
