import http from "../../services/httpService";
import {
  getLocalStorageToken,
  removeAllLocalStorageItems,
  setLocalStorageCurrentUserData,
  setLocalStorageCurrentUserName,
  setLocalStorageToken,
} from "../../services/localStorage";
import qs from "qs";
// Config Data
import config from "../../config.json";
const { apiUrl, line_client_id, line_client_secret, line_auth_url } = config;

const apiEndpoint = apiUrl + "api/auth";

// http.setToken(getToken());

export function getToken() {
  return getLocalStorageToken();
}

export async function login(username, password) {
  const config = { username, password };
  const { data } = await http.post(apiEndpoint + "/login", config);
  await setLocalStorageToken(data.access_token);
  if (data.userInfo) {
    const userInfo = data.userInfo;
    await setLocalStorageCurrentUserName(userInfo.memberName);
    await setLocalStorageCurrentUserData({
      ...userInfo,
      name: userInfo.memberName,
    });
  }
  await http.setToken(data.access_token);
  return data;
}

export async function loginWithToken(token) {
  await setLocalStorageToken(token);
  await http.setToken(token);
}

export async function register(params) {
  const { data: response } = await http.post(
    apiUrl + "api/auth/register",
    params
  );
  return response;
}

export function checkEmailAndPhone(data) {
  return http.post(apiUrl + "api/auth/checkEmailAndPhone", data);
}

export function sendOtp(data) {
  return http.post(apiUrl + "api/auth/sendOtp", data);
}

export function verifyOtp(data) {
  return http.post(apiUrl + "api/auth/verifyOtp", data);
}

export function checkLogin() {
  try {
    const data = getLocalStorageToken();
    return data;
  } catch (ex) {
    return null;
  }
}

export function logout() {
  removeAllLocalStorageItems();
  http.removeToken();
  window.location.reload();
}

export async function getLineToken(code, line_redirect_uri) {
  const result = await http.post(
    line_auth_url,
    qs.stringify({
      grant_type: "authorization_code",
      code: code,
      redirect_uri: line_redirect_uri,
      client_id: line_client_id,
      client_secret: line_client_secret,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return result;
}

export async function sendLineCode(code, token, id, register, allData) {
  const { data } = await http.get(
    apiUrl +
      "api/auth/sendLineCode?code=" +
      code +
      "&token=" +
      token +
      "&memberId=" +
      id +
      "&register=" +
      register +
      "&data=" +
      allData
  );

  if (data.result && data.isMember) {
    const userInfo = { id: data.memberId, name: data.memberName };
    setLocalStorageToken(data.accessTokenResponse);
    setLocalStorageCurrentUserName(data.memberName);
    setLocalStorageCurrentUserData(userInfo);
    http.setToken(data.accessTokenResponse);
  }
  return data;
}

export async function registerWithLine(code, token, id, register, allData) {
  const { data } = await http.get(
    apiUrl +
      "api/auth/registerWithLine?code=" +
      code +
      "&token=" +
      token +
      "&memberId=" +
      id +
      "&register=" +
      register +
      "&data=" +
      allData
  );
  return data;
}

export async function loginWithFacebook(data) {
  return http.post(apiUrl + "api/auth/loginWithFacebook", data);
}

export async function registerWithFacebook(data) {
  return http.post(apiUrl + "api/auth/registerWithFacebook", data);
}

export function sendGoogleCode(code) {
  return http.get(apiUrl + "api/auth/sendGoogleCode?code=" + code);
}

export function registerWithGoogle(code) {
  return http.get(apiUrl + "api/auth/registerWithGoogle?code=" + code);
}

export function changePassword(data) {
  return http.post(apiUrl + "api/auth/changePassword", data);
}

export function forgetPassword(data) {
  return http.post(apiUrl + "api/auth/forgetPassword", data);
}

export function checkToken(token) {
  return http.post(apiUrl + "api/auth/checkToken?token=" + token);
}

export function resetPassword(data) {
  return http.post(apiUrl + "api/auth/resetPassword", data);
}

export function getUuid() {
  return http.get(apiUrl + "api/auth/getUuid");
}
