import React from "react";

export default function Textarea({ name, cols = 30, rows = 5, ...rest }) {
  return (
    <textarea
      className="form-control"
      //   placeholder={languageData.enterComment}
      //   onChange={(e) => setComment(e.target.value)}
      id={name}
      name={name}
      cols={cols}
      rows={rows}
      {...rest}
    />
  );
}
