import http from "../../services/httpService";
import config from "../../config.json";

const { apiUrl } = config;
const apiEndPoint = apiUrl + "api/product/";

export function getProducts(params) {
  return http.get(apiEndPoint + "getProduct", params);
}

export function getProductById(params) {
  return http.get(apiEndPoint + "getProductById", params);
}

export function getPointProducts(params) {
  return http.get(apiEndPoint + "getPointProducts", params);
}

export function getCartProduct(params) {
  return http.post(apiEndPoint + "getCartProduct", params);
}
