import React from "react";
import Lottie from "lottie-react";
import animation from "../../../assets/loading_animation.json";

export default function Loading({
  isLoad,
  size = "sm", // "sm", "lg"
}) {
  if (isLoad) {
    if (size === "sm")
      return (
        <div id="small-loading">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Lottie
              animationData={animation}
              style={{ width: 100, height: 100, marginTop: "30px" }}
            />
          </div>
          {/* <img id="loading-image" src={loadingLogo} alt="Loading..." /> */}
        </div>
      );

    return (
      <div id="loading">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Lottie
            animationData={animation}
            style={{ width: 70, height: 70, marginTop: "15px" }}
          />
        </div>
        {/* <img id="loading-image" src={loadingLogo} alt="Loading..." /> */}
      </div>
    );
  }
}
