export const numberWithCommas = (x) => {
  let data = x;
  if (data == 0) {
    return data;
  }
  data = Math.round(data * 100) / 100;
  if (data) {
    return data.toLocaleString("en", {
      minimumFractionDigits: data % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    });
  }
};

export const queueFormat = (queue, queueLength = 3) => {
  if (queue == null) {
    return "";
  }
  let queueNo = "Q";
  for (let i = queue.toString().length; i < queueLength; i++) {
    queueNo += "0";
  }
  return queueNo + queue;
};
