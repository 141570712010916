import http from "./httpService";
import {
  setLocalStorageLanguageId,
  setLocalStorageLanguageImage,
  setLocalStorageLanguageName,
  setLocalStorageLanguagePath,
} from "../services/localStorage";
import config from "../config.json";

const { apiUrl } = config;

const apiEndPoint = apiUrl + "api/alert/";

export function getAlerts(params) {
  return http.get(apiEndPoint + "get", params);
}

export function getNotificationCount() {
  return http.get(apiEndPoint + "getNotificationCount");
}

export function readAlert(id) {
  return http.post(apiEndPoint + "readAlert/" + id);
}
