import { InlineIcon } from "@iconify/react";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from "@iconify/icons-mdi/calendar";

export const DateInput = ({
  name,
  label,
  value,
  error,
  minDate,
  maxDate,
  dateFormat = "dd-MM-yyyy",
  disabled,
  placeholder = "date",
  ...rest
}) => {
  return (
    <>
      <DatePicker
        showIcon
        key={name}
        isClearable={value != ""}
        disabled={disabled}
        {...rest}
        selected={value}
        minDate={minDate || null}
        maxDate={maxDate || null}
        dateFormat={dateFormat}
        name={name}
        id={name}
        className="form-control border date-picker"
        placeholderText={placeholder}
        autoComplete="off"
        icon={<InlineIcon icon={calendarIcon} style={{ color: "gray" }} />}
      />
      {error && <small className="text-danger help-block">{error}</small>}
    </>
  );
};
