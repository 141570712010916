import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faShop,
  faShoppingCart,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import CommonContext from "../context/CommonContext";
import { getToken } from "../components/auth/authService";
import {
  faFacebook,
  faGoogle,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  const commonContext = useContext(CommonContext);
  const { languageData, cartCount } = commonContext;

  return (
    <div>
      {/* Footer Web Version */}
      {/* <footer className="footer-web-version d-none d-lg-block d-md-block d-sm-none">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h4 className="footer-title">Customer Service</h4>
              <ul className="footer-links">
                <li>
                  <Link>Contact Us</Link>
                </li>
                <li>
                  <Link>FAQs</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h4 className="footer-title">About Us</h4>
              <ul className="footer-links list-unstyled">
                <li>
                  <Link>Instapos Shop</Link>
                </li>
                <li>
                  <Link>Terms and Conditions</Link>
                </li>
              </ul>
              <div className="p-3"></div>
            </div>
            <div className="col-md-4">
              <p className="footer-title-sub">
                Subscribe for news and promotions
              </p>
              <div className="mb-3 input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                />
                <span className="input-group-text" id="basic-addon2">
                  <button
                    className="btn btn-orange border-left-0"
                    type="submit"
                  >
                    Subscribe
                  </button>
                </span>
              </div>

              <p className="footer-title-sub">Follow Us</p>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link className="social-link">
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link className="social-link">
                    <FontAwesomeIcon icon={faGoogle} />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link className="social-link">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="pt-4 row justify-content-center">
              <div className="text-center col-md-8">
                <p className="mb-0 copyrights">
                  © 2023 Instapos company. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer> */}

      {/* Footer Mobile Version */}
      {/* <footer className="footer-fixed">
        <Link to={"/home"} className="footer-fixed-link active">
          <FontAwesomeIcon icon={faHome} className="icon" />
          <div>
            <span>{languageData.home}</span>
          </div>
        </Link>
        <Link className="footer-fixed-link">
          <FontAwesomeIcon icon={faCreditCard} className="icon" />
          <div>
            <span>{languageData.pointCards}</span>
          </div>
        </Link>
        <Link to="/shopList" className="footer-fixed-link">
          <FontAwesomeIcon icon={faShop} className="icon" />
          <div>
            <span>{languageData.shops}</span>
          </div>
        </Link>
        <Link to="/cartList" className="footer-fixed-link">
          <FontAwesomeIcon icon={faShoppingCart} className="icon" />
          <div>
            <span>{languageData.cart}</span>
          </div>
          {getToken() && (
            <span className="small badge bg-primary footer-fixed-cart-count">
              {cartCount}
            </span>
          )}
        </Link>
      </footer> */}
    </div>
  );
}
