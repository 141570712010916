import http from "./httpService";
import config from "../config.json";

const { apiUrl, adminApiUrl } = config;

const apiEndPoint = apiUrl + "api/payment/";

export function getAllPayment() {
  return http.get(apiEndPoint + "getAllPaymentTypes");
}

export function getAllPaymentTypes(id) {
  return http.get(apiEndPoint + "getAllPaymentTypesByShop/" + id);
}

export function getPosOrderPaymentQRCode(id) {
  return http.get(apiEndPoint + "getPosOrderPaymentQRCode/" + id);
}

export function completePayment(id) {
  return http.post(apiEndPoint + "completePayment/" + id);
}

export async function getPaymentQRCode(params) {
  const url = `${adminApiUrl}api/payment/getBillPaymentQRCode`;
  try {
    const response = await fetch(url, {
      method: "POST", // Specifies the HTTP method
      headers: {
        "Content-Type": "application/json", // Indicates the content type is JSON
      },
      body: JSON.stringify(params), // Converts the params object to a JSON string
    });

    // Check if the response status is OK (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    // Handle errors such as network issues or JSON parsing errors
    console.error("There was an error fetching the payment QR code:", error);
    throw error; // Re-throw the error for further handling, if needed
  }
}
