import config from "../config.json";

const { notiSoundUrl } = config;
let audio = new Audio(notiSoundUrl); // Declare audio outside the function

const playNotificationSound = () => {
  // If audio is not loaded yet, load it in muted state
  if (!audio.src) {
    audio.muted = true;
    audio.src = notiSoundUrl;
    audio.load();
    audio.play().catch((e) => console.error("Error playing sound:", e));
  }

  // When you want to play the sound, unmute it
  audio.muted = false;
  audio.play().catch((e) => console.error("Error playing sound:", e));
};

export { playNotificationSound };
