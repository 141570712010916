import http from "./httpService";
import config from "../config.json";

const { apiUrl } = config;

const apiEndPoint = apiUrl + "api/discount/";

export function getDiscountByShopId(shopId) {
  return http.get(apiEndPoint + "getDiscountByShopId/" + shopId);
}

export function getDiscount(data) {
  return http.get(apiEndPoint + "getDiscount", data);
}
